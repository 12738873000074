import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

const Aboutdetails = () => (
  <React.Fragment>
    <Helmet>
      <title>About - Blinds | Shutters | Curtains | Birk</title>
      <meta
        property="og:title"
        content="About - Blinds | Shutters | Curtains | Birk"
      />
      <meta
        name="keywords"
        content="Roller Blinds, Roman Blinds, Curtains, Shutters, Venetians, External Range, Automation, Honeycomb Blinds"
      />
      <meta
        name="description"
        content="Over 30 years experience in bespoke Window Furnishings. Please contact for Roller Blinds, Roman Blinds, Curtains, Shutters, Venetians, External Range, Automation, Honeycomb Blinds. We are operated in Melbourne and Brisbane"
      />
      <meta
        property="og:description"
        content="Over 30 years experience in bespoke Window Furnishings. Please contact for Roller Blinds, Roman Blinds, Curtains, Shutters, Venetians, External Range, Automation, Honeycomb Blinds. We are operated in Melbourne and Brisbane"
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="http://birk.itechnotion.com/about" />
      <link rel="canonical" href="http://birk.itechnotion.com/about" />
    </Helmet>
    <Header />
    <section className="about-section" id="about-us">
      <div className="bg-shape1">
        <div className="container-fluid all-section">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="about-content">
                <div className="heading">
                  <h2>About Us</h2>
                </div>
                <div className="about-txt">
                  <p>
                    Birk Blinds is an Australian company and industry leader,
                    with over 30 years’ experience in bespoke window furnishing
                    design and installation.
                  </p>
                  <p>
                    Our expert Design Consultants will work in partnership with
                    you or your interior designer to bring your vision to life.
                    We ensure every detail of your window furnishings is
                    considered and delivered to the highest standard.
                  </p>
                </div>

                <div className="about-txt">
                  <h5>
                    <span className="text-dark">Why Birk? </span>
                  </h5>
                  <div className="why-list ml-4">
                    <ul id="mission">
                      <li id="vision">
                        Over 30 years experience in bespoke Window Furnishings
                      </li>
                      <li>Design consultation with experienced design team</li>
                      <li>Latest automation and technology solutions</li>
                      <li>Custom, Australia made products</li>
                      <li>Globally sourced fabrics</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="about-img">
                <StaticImage
                  src="../images/babout3.png"
                  alt="Alfresco Photo With Curtains"
                  className="img-fluid"
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="about-content">
                <div className="about-txt">
                  <h5>
                    <span className="text-dark">Mission </span>
                  </h5>
                  <p>
                    For more than quarter of a century, Australians have
                    entrusted Birk to design, manufacture and install window
                    coverings that go beyond functional to fashionable. Birk
                    window coverings do more than just block glare and add
                    privacy. They add flare, character and of course, that
                    finishing touch that completes and adds value to your home
                    or apartment.
                  </p>
                  <p>
                    Birk offers a full range of window covering products
                    including roller blinds, roman blinds, timber venetians,
                    shutters, drapes, pelmets, panel glide blinds, awnings and
                    specialized home automation integration, which includes
                    motorization.
                  </p>
                  <p>
                    We cater to all clients and budgets from first home buyer,
                    to investor, growing families and high end residential
                    homes. Whatever level of client, Birk strives to achieve
                    complete customer satisfaction and aims to put the finishing
                    touch to any home or apartment.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="about-content">
                <div className="about-txt">
                  <h5>
                    <span className="text-dark">Vision</span>
                  </h5>
                  <p>
                    Our focus is always on the future. And, so we aim to
                    contribute to a better and cleaner world. We invest in
                    sustainable products and design, we take care of our people
                    and our local environment and we support others in their
                    vision to do the same.
                  </p>
                  <p>
                    We employ qualified interior designers at our display
                    galleries that coordinate window coverings with interior
                    paint colors, flooring, skirting and furniture. It is all
                    about creating a harmonious look that accentuates, rather
                    than dominating each space.
                  </p>
                  <p>
                    For us, your window coverings are the top tier of decorating
                    - the element that brings everything together for the home
                    of your dreams.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="top-bg-shape">
          <StaticImage
            src="../images/about-chair.svg"
            alt="Grey Chair"
            className="img-fluid"
          />
        </div>
        <div className="bottom-bg-shape">
          <StaticImage
            src="../images/Group101.svg"
            alt="Grey Potplant"
            className="img-fluid"
          />
        </div>
      </div>
    </section>

    <section className="passion-section pb-2" id="sustainability">
      <div className="container-fluid">
        <div className="row align-items-center mb-5">
          <div className="col-lg-6 col-md-12">
            <div className="about-img">
              <StaticImage
                src="../images/bchoose1.png"
                alt="Alfresco Side View With Curtains"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="about-content">
              <div className="heading">
                <h2>Sustainability</h2>
              </div>
              <div className="about-txt">
                <h5>
                  <span className="text-dark">
                    Why we support a sustainable tomorrow
                  </span>
                </h5>
                <p>
                  Birk keeps your home and office interiors on trend and on
                  brief but we also look beyond Birk, to your outside space and
                  that of your neighbours. Afterall, our environment is a shared
                  space and we think that’s an exterior worth protecting with
                  the same care and attention you afford your interiors.
                </p>
                <p>
                  According to the Natural Resources Defense Council, your home
                  loses approximately 1/3 of its total heat through windows and
                  doors. You don’t have to be a climate expert to know that’s
                  putting a heavy load on your power-driven, energy-consuming
                  insulators. Imagine Birk window coverings as an eco-conscious
                  alternative, helping to block out summer sun and insulate
                  against winter winds.
                </p>
                <p>
                  For us, we prefer to work with suppliers who are also
                  diligently working towards a more sustainable tomorrow. Among
                  their certifications you’ll find Ecospecifier Global,
                  representing products that are both environmentally-aware and
                  third party tested; Oeko-Tex® tests textiles and only endorses
                  those that satisfy safe levels of chemical substances; and
                  Greenguard, which is all about air quality, stringently
                  testing products for emissions into the air that we breathe.
                  Birk beautifully balances fashion and flair with environmental
                  care. Rest assured, you can combine your exterior
                  eco-conscience with the finest interior design.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="about-content">
              <div className="about-txt">
                <h5>
                  <span className="text-dark">
                    Why you should support a sustainable tomorrow
                  </span>
                </h5>
                <p>
                  Thanks to Birk, you will never have to compromise on quality
                  and sophistication when considering window dressings that
                  serve your style as well as your environment. Birk window
                  coverings provide the opportunity to tread lightly on the
                  planet.
                </p>
                <p>
                  Whatever your requirements – be it roller blinds, shutters,
                  panel glides or curtains - by choosing window coverings you’ve
                  automatically opted to insulate your home. If you’re looking
                  to cut your power bill and the inherent pull on your
                  air-conditioning units and their emissions, consider Birk as a
                  logical step in your power solution. Allow your window
                  coverings to do the heavy-lifting for you. They’ll trap warm
                  air inside during winter and block out the sun’s rays in
                  summer.
                </p>
                <p>
                  Maximise your home’s potential, reduce your carbon footprint
                  and your power bills – with Birk.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </React.Fragment>
);
export default Aboutdetails;
